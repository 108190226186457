import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Video from '../components/Video'
import Carousel from '../components/Carousel/Carousel';
import { Content } from '../components/Content/Content';
//import Features from '../components/Features/Features';
import Hero from '../components/Hero/Hero';
import { heroOne, heroTwo, heroThree } from '../components/data/HeroData';
//import { heroFive, heroFour, heroSix } from '../components/data/HeroData2';

const Home = () => {
  return (
    <div>
        <Navbar />

        <Video />
        <Content {...heroOne} />
			<Content {...heroTwo} />
			<Content {...heroThree} />
      <Carousel />

        

        <Footer />
    </div>
  )
}

export default Home