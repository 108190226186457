
import './Form.css' 
import { TextWrapper, Row } from '../globalStyles'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const Form = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5f7lfxu', 'template_21hz47e', form.current, '6VRVPLf37HoCmAuFD')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      e.target.message("Message sent");
  };


  return ( 
    <div className="form">  
      <form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="user_name"></input>
        <label>Email</label>
        <input type="text" name="user_email"></input>
        <label>Number</label>
        <input type="text" name="user_number"></input>
        <label>Details</label>
        <textarea name="message" rows='6' placeholder='Type a message here' />
        <button type="submit" value="Send" className="btn">Get In Touch</button>
      </form>  
      <div> 
      <TextWrapper size="2rem">
      <h1>Office:</h1>
      <p>7771 W Oakland Park Blvd,</p>
      <p>Suite 105-B</p>
      <p>Sunrise, FL 33351</p>
      <p>Phone: (954) 744-8373</p>
      <p>Fax: (954) 744-8376</p>
      <p>Toll-Free: 1(866) 55-ISORA</p>
      <h1>Location:</h1> 
      </TextWrapper>
      </div>
    <iframe width="50%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=7771%20West%20Oakland%20Park%20Blvd%20Suite105-B%20Sunrise%20FL%2033351+(Isora%20Care%20Inc)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure acres/hectares on map</a></iframe>
    </div>
  )
}

export default Form
