import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {FaBars, FaTimes, FaMailBulk, FaPhone, FaAlignCenter} from 'react-icons/fa'
import './Navbar.css'

const Navbar = () => {
  const[click,setClick] =useState(false) 
  const handleClick =() => setClick(!click)




  return (
    <div className='header'>
        <Link to='/'><h3><FaMailBulk size={25} style={{color:'#ffffff', marginRight: '2rem'}}/>Info@IsoraCare.com</h3></Link>
        <h3><FaPhone size={25} style={{color:'#ffffff', marginRight:'2rem'}}/>1(866) 55-ISORA</h3>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li>
                <Link to='/'></Link>
            </li>
            <li>
                <Link to='/' style={{color:'#ffffff'}}>Home</Link>
            </li>
            <li>
                <Link to='/excursion'style={{color:'#ffffff'}}>Our Services</Link>
            </li>
            <li>
                <Link to='/gallery'style={{color:'#ffffff'}}>Our Caregivers</Link>
            </li>
            <li>
                <Link to='/about'style={{color:'#ffffff'}}>About Us</Link>
            </li>
            <li>
                <Link to='/contact'style={{color:'#ffffff'}}>Contact Us</Link>
            </li>
        </ul>
        <div className='hamburger' onClick={handleClick}>
        {click ? (<FaTimes size={20} style={{color: '#fff'}} />) : (<FaBars size={20} style={{color: '#fff'}}/>)}


            
        </div>
    </div>
  )
}

export default Navbar
