import React, { useState } from 'react';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { data, sliderSettings } from '../data/CarouselData';
import { Row, Heading, Section, TextWrapper } from '../globalStyles';
import {
	ButtonContainer,
	ReviewSlider,
	ImageWrapper,
	CarouselImage,
	CardButton,
	h1,
} from './CarouselStyles'; 
import {Link} from 'react-router-dom'

const Carousel = () => {
	const [sliderRef, setSliderRef] = useState(null);

	return (
		<Section color='#000000' margin="auto"  padding="50px 70px" >
			<Row justify="space-between" margin="1rem" wrap="wrap">
				<Heading width="auto" >
					Our Values 
				</Heading>
				<h1 color=""> Isora Care guarantee that we do not discriminate on the basis of creed, color, gender, age, or national origin, in providing care for each of our patients, nor to any potentially qualified caregivers. We welcome everyone who is in need of care or who wishes to join our team.</h1>
				<ButtonContainer>
					<IconContext.Provider value={{ size: '3rem', color: '#fff' }}>
						<FaArrowCircleLeft onClick={sliderRef?.slickPrev} />
						<FaArrowCircleRight onClick={sliderRef?.slickNext} />
					</IconContext.Provider>
				</ButtonContainer>
			</Row>

			<ReviewSlider {...sliderSettings} ref={setSliderRef}>
				{data.map((el, index) => (
					<ImageWrapper key={index}>
						<CarouselImage src={el.image} />
						<TextWrapper size="1.1rem" margin="0.4rem 0 0"color="#fff" weight="bold">
							{el.title}
						</TextWrapper>
						<TextWrapper size="0.9rem" margin="0.7rem" color="#fff">
							{el.description}
						</TextWrapper>
						<CardButton color="#fff" ><Link to='/about'><h4>Learn More</h4></Link></CardButton>
						
					</ImageWrapper>
				))}
			</ReviewSlider>
		</Section>
	);
};

export default Carousel;
