import React from 'react'
import { Heading, Section, TextWrapper, Container } from '../globalStyles';
const Caregiver = () => {
    return (  

    <div> 
        <Section>   
         <Container> 
          <Heading width = "auto">
            <div className='care'><h5>Our Care Providers</h5> 
            </div>
          </Heading>
           <TextWrapper size="2rem"><p> Isora Care Inc has a team of qualified and experienced care providers who are dedicated to providing care services specific to your needs and preferences. Our caregivers focus on maintaining the well-being and safety of our clients and aim to improve their quality of life.</p></TextWrapper> 
           <Heading>
          <div className='providers'><break><h5> Our Providers</h5></break></div>
            </Heading>   
            <TextWrapper size="1.5rem">
             <li size="1.5rem">Registered Nurse (RN)</li> 
             <li>Licensed Practical Nurses (LPN)</li>
             <li>Certified Nursing Assistants (CNA)</li>
            </TextWrapper> 
          <TextWrapper size="1.5rem"> 
          <li size="5rem">Home Health Aides (HHA)</li>
              <li>Companion Sitters</li>
              <li>Homemakers</li>  
          </TextWrapper>
          </Container> 
          </Section>
    </div>
    )}


export default Caregiver;