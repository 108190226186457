import React from 'react'
import './Footer.css'
import {FaFacebook, FaInstagram, FaMailBulk, FaPhone, FaSearchLocation, FaTwitter, FaFax, FaLinkedin} from 'react-icons/fa'  
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-container">
            <div className="left"> 
                <div className="location">
                    <FaSearchLocation size={20} style={{color:'#ffffff', marginRight: '2rem'}} />
                </div>
                <div>
                    <p>7771 W Oakland Park Blvd,

                    Suite 105-B,
                    
                    Sunrise, FL 33351</p>
                </div> 
            </div>
            <div className="phone">
                <h4><FaPhone size={20} style={{color:'#ffffff', marginRight: '2rem'}} />Number: 1(866) 55-ISORA</h4>
            </div>
            <div className="phone">
                <h4><FaPhone size={20} style={{color:'#ffffff', marginRight: '2rem'}} />Number: (954) 744-8373</h4>
            </div>
            <div className="fax">
                <h4><FaFax size={20} style={{color:'#ffffff', marginRight: '2rem'}} />Fax: (954) 744-8376</h4>
            </div>
            <div className="email">
                <h4><FaMailBulk size={20} style={{color:'#ffffff', marginRight: '2rem'}} />Email: info@IsoraCare.com</h4>
            </div>
            <div className="about">
                <h4>ISORA CARE INC © 2022. All Rights Reserved.</h4> 
                <h4>State Licensed Nurse Registry, Broward County, License #NR30212475</h4>
            </div>
            <div className="social">
                <a href="https://www.instagram.com/isoracare/"><FaInstagram size={30} style={{color:'#ffffff', marginRight: '1rem'}} /></a>
                <a href="https://twitter.com/isoracare"><FaTwitter size={30} style={{color:'#ffffff', marginRight: '1rem'}} /></a>
                <a href="https://www.facebook.com/people/Isora-Care-Inc/100086813577844/?sk=about"><FaFacebook size={30} style={{color:'#ffffff', marginRight: '1rem'}} /></a> 
                <a href="https://www.linkedin.com/company/isora-care-inc"><FaLinkedin size={30} style={{color:'#ffffff', marginRight:'1rem'}}/></a>  
            </div>  
        </div> 
    </div>
  )
}

export default Footer