import React from 'react'
import {Routes, Route} from 'react-router-dom' 

import Contact from './routes/Contact'
import Home from './routes/Home'
import Pricing from './routes/Pricing'
import Excursion from './routes/Excursion'
import Gallery from './routes/Gallery'
import Hero from './components/Pricing/Hero'
import About from './routes/About'


function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/excursion' element={<Excursion />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
