import React from 'react'
import {Link} from 'react-router-dom'
import './Video.css'

import video from '../assets/Video.mp4'

const Video = () => {
  return (
    <div className='hero'>
      <video autoPlay loop playsInline muted id='video'>
        <source src={video} type='video/mp4' />
      </video>  
    <div className='content'>
      <h1>  </h1>
       
    
    <div>
  
      {/*<Link to='/contact' className='btn btn-light'>Launch</Link>*/}
    </div>
    </div>
    </div>
  
  )
}

export default Video