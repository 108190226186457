import {Link} from 'react-router-dom'
export const heroOne = {
	reverse: true,
	inverse: true,
	topLine: {
		text: '',
	},
	headline: "We Provide Individualized Care Solutions to Our Clients",
	description: 'Isora Care Inc provides the highest standard of home health care services in Broward County, Florida.We aim to exceed our Clients expectations as we create solutions to their individualized needs.',
	buttonLabel: <Link to='/contact'><h4>Contact Us</h4></Link> ,

	linkTo:'/contact',
	imgStart: 'start',
	img: '../../assets/Health8.jpg',
	
	start: 'true',
};

export const heroTwo = {
	reverse: false,
	inverse: false,
	topLine: {
		text: "",
	},
	headline: 'Our Mission',
	description: 'Isora Care Inc provides the solution to better living with individualized care by realizing your specialized needs and preferences. Our team of experienced caregivers aim to maintain your well-being, safety, and quality of life.',
	buttonLabel: <Link to='/excursion'><h4>Our Services</h4></Link>,

	linkTo: '/excursion',
	imgStart: 'start',
	img: '../../assets/Health2.jpg',
	start: 'true',
};

export const heroThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: '',
	},
	headline: 'Our Team of Providers',
	description: 'Isora Care Inc is commited to providing the most qualified and experienced team of caregivers to fulfill on the specific needs and references of our clients. ', 
	buttonLabel: <Link to='/gallery'><h4>Our Caregivers</h4></Link>,

	linkTo: '/galley',
	imgStart: '',
	img: '../../assets/Health4.jpg',
	start: 'true',
	
};

