import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Hero from '../components/Pricing/Hero'
import Carousel from '../components/Carousel/Carousel';
import { Content } from '../components/Content/Content';
//import PricingCard from '../components/Pricing/Pricing'
import { heroOne, heroTwo, heroThree } from '../components/data/HeroData3';

const About = () => {
  return (
    <div>
        <Navbar />
        <Hero heading="About Us" text=""/>
        <Content {...heroOne} />
        <Content {...heroTwo} />
        <Content {...heroThree} />
        <Footer />
    </div>
  )
}

export default About
