export const data = [	
	{
		title: 'Compassion',
		description:
			"Our caregivers are experienced and compassionate. They have been professionally trained to give personalized home care services, to maintain our client's quality of life.",
		image: '../../assets/Health0.jpg',
	},
	{
		title: 'Discipline ',
		description: ' We are committed to maintaining the highest quality of care for our clients, keeping care constant during their most critical times and providing comfort to their families.',
		image: './assets/Health8.jpg',
	},
	{
		title: 'Integrity',
		description: "We aim to connect all resources with our standard of care to facilitate our client's desires and preferences. We will work assiduously to fulfill on their needs.",
		image: './assets/Health5.jpg',
	},
	
];

export const sliderSettings = {
	arrows: false,
	slidesToShow: 3,
	focusOnselect: false,
	accessability: false,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2,
			},
		},

		{
			breakpoint: 900,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};
