import React from 'react'

import Footer from '../components/Footer'
//import Gallery from '../components/Gallery/Gallery'
//import Reccomend from '../components/Reccomend/Reccomend'
import Navbar from '../components/Navbar'
import Hero from '../components/Pricing/Hero'
import Caregivers from '../components/Caregivers/Caregivers'

const Gallery = () => {
  return (
    <div>
        <Navbar />
        <Hero heading="Our Caregivers" text="" />
        
        <Caregivers />
        <Footer />
    </div>
  )
}

export default Gallery