import React from 'react'
//import Form from '../components/Contact/Form'
//import Form from '../components/Contact/Form'
//import Carousel from '../components/Carousel/Carousel';
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Hero from '../components/Pricing/Hero'
import Reccomend from '../components/Reccomend/Reccomend'

const Excursion = () => {
  return (
    <div>
        <Navbar />
        <Hero heading="Our Services" text="" />
        
        <Reccomend/>
        
        <Footer />
    </div>
  )
}

export default Excursion