import React, { useState } from "react";
import styled from "styled-components";
import Destination1 from "../../assets/Health9.jpg";
import Destination2 from "../../assets/Health7.jpg";
import Destination3 from "../../assets/Health6.jpg";
import Destination4 from "../../assets/Health5.jpg";
import Destination5 from "../../assets/Health0.jpg";
import Destination6 from "../../assets/Health4.jpg";
import Destination7 from "../../assets/Health10.jpg";
import Destination8 from "../../assets/Health12.jpg";




import './Reccomend.css'


export default function Recommend() {
  const data = [
    {
      image: Destination1,
      title: "Skilled Nursing",
      subTitle: "Our trained, licensed and experienced nurses are able to provide nursing services such as; head to toe assessments, monitor vital signs, administer physician-prescribed medication, wound care, coordinating with other careproviders individuals in the patient’s care plan and keeping the patient comfortable through palliative care.",
    },
    {
      image: Destination2,
      title: "Personal Care",
      subTitle: "Our qualified and experienced caregivers provides a range of personal care services to our clients based on their needs and desires. These services may include bathing, showering, applying creams and lotions, dressing, toileting, feeding,  and other day-to-day duties to maintain your quality of life.",
      
    },
      
    {
      image: Destination3,
      title: "Homemaker",
      subTitle: "Our clients can benefit from our supportive services that ensure a safe and healthy environment in their homes. Our caregivers are trained to assist our clients with meal preparation, household cleaning, laundry, running errands, shopping for personal items and other household chores.",
      
     
    },
    {
      image: Destination4,
      title: "Companion Care",
      subTitle: "At Isora Care Inc we focus on improving the health and well-being of our clients so they can get the most out of life. Our caregivers provide caring companionship and conversation, helping with hobbies, gardening, shopping and errands, accompanying clients to appointments and reminder services.",
    },
    {
      image: Destination5,
      title: "Respite Care",
      subTitle: " We understand that while family members may love taking care of their loved ones, they may also need breaks to care for themselves, to go on vacation or for appointments. We offer respite care services to our clients so they may be able to remain in the comfort of their homes during these times.",
      
    },
    {
      image: Destination6,
      title: "24/hr Living Care",
      subTitle: "We have experienced caregivers who specialize in 24 hours live-in care services. Families may take comfort in knowing that our clients are cared for around the clock and have a constant companion. Their care is individualized and they will be able to remain in the comfort of their own homes.",
      
    
    },
    {
      image: Destination7,
      title: "Concierge",
      subTitle: "We provide concierge services to our clients who may need assistance with non-medical services. We focus on the specific needs of the client and aim to fulfill on their request with errands, personal assistance, activity planning, pickup and delivery, accompanying to events and pet care.",
      
    
    },
    {
      image: Destination8,
      title: "Supplementary Staffing",
      subTitle: "Isora Care Inc contracts with other health facilities and agencies to provide caregivers to supplement their staff. We have experienced and qualified caregivers who are able to work in these types of settings and will provide support in times of peak loads, for special projects and during unexpected staff shortages. ",
      
    
    },
  
  
  ];

  const packages = [
  
  ];

  const [active, setActive] = useState(1);
  return (
    <Section id="recommend">
      <div className="title">
        <h2></h2>
      </div>
      <div className="packages">
        <ul>
          {packages.map((pkg, index) => {
            return (
              <li
                className={active === index + 1 ? "active" : ""}
                onClick={() => setActive(index + 1)}
              >
                {pkg}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="destinations">
        {data.map((destination) => {
          return (
            <div className="destination">
              <img src={destination.image} alt="" />
              <h3>{destination.title}</h3>
              <p color="#fff">{destination.subTitle}</p>
              <div className="info">
                <div className="services">
                  {/*<img src={Destination6} alt="" />*/}
                  {/*<img src={Destination2} alt="" />*/}
                 {/* <img src={Destination5} alt="" />*/}
                </div>
                <h4>{destination.cost}</h4>
              </div>
              <div className="distance">
                <span></span>
                <span>{destination.duration}</span>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

const Section = styled.section`
  padding: 2rem 0;
  .title {
    text-align: center;
    color: white;
  }
  
  .packages {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    ul {
      display: flex;
      list-style-type: none;
      width: max-content;
      li {
        padding: 1rem 2rem;
        border-bottom: 0.1rem solid black;
      }
      .active {
        border-bottom: 0.5rem solid #8D9C3D;
      }
    }
  }
  .destinations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    padding: 0 3rem;
    .destination {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background-color: #8D9C3D;
      border-radius: 1rem;
      transition: 0.3s ease-in-out;
      color: #fff;
      &:hover {
        transform: translateX(0.4rem) translateY(-1rem);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      img {
        width: 100%;
      }
      .info {
        display: flex;
        align-items: center;
        .services {
          display: flex;
          gap: 0.3rem;
          img {
            border-radius: 1rem;
            background-color: #4d2ddb84;
            width: 2rem;
            /* padding: 1rem; */
            padding: 0.3rem 0.4rem;
          }
        }
        display: flex;
        justify-content: space-between;
      }
      .distance {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 768px) {
    .packages {
      ul {
        li {
          padding: 0 0.5rem;
          font-size: 2vh;
          padding-bottom: 1rem;
        }
        .active {
          border-bottom-width: 0.3rem;
        }
      }
    }
    .destinations {
      grid-template-columns: 1fr;
      padding: 0;
    }
  }
`;
