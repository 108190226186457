import React from 'react'
import Form from '../components/Contact/Form1'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Hero from '../components/Pricing/Hero'

const Contact = () => {
  return (
    <div>
        <Navbar />
        <Hero heading="Contact Us" text="" />
        <Form />
        <Footer />
    </div>
  )
}

export default Contact