import {Link} from 'react-router-dom' 

export const heroOne = {
	reverse: true,
	inverse: true,
	topLine: {
		text: '',
	},
	headline: "Personalized Exceptional Care",
	description:'With Isora Care, our patients will be able to continue to do the things they love while receiving quality care in the convenience of their home. We are committed to promoting the best quality of life for our patients so they can continue to live independently.',
	buttonLabel:<Link to="/contact"><h4>Contact Our Team</h4></Link>,
	linkTo: '/contact',
	imgStart: 'start',
	img: '../../assets/Health3.jpg',
	start: 'true',
};

export const heroTwo = {
	reverse: false,
	inverse: false,
	topLine: {
		text: "",
	},
	headline: 'What We Do',
	description: 'Our services allow our patients to live more independently in the comfort of their home with the assistance of our experienced and qualified caregivers. We offer personal care, homemaker services, respite care and 24 hour live-in services.',
	buttonLabel: <Link to="/excursion"><h4>Our Services</h4></Link>,

	linkTo: '/excursion',
	imgStart: 'start',
	img: '../../assets/Health1.jpg',
	start: 'true',
};

export const heroThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: '',
	},
	headline: 'Our Caregivers',
	description: 'We have an exceptional team of experienced and qualified caregivers who are committed to providing quality home care to our patients.',
	buttonLabel: <Link to="/gallery"><h4>Find out more</h4></Link>,

	linkTo: '/gallery',
	imgStart: '',
	img: '../../assets/Health4.jpg',
	start: 'true',
	
};

